import { createSlice } from "@reduxjs/toolkit";

import type {
  PostTypeOption,
  PreviewPostButtonState,
  PreviewPostConfig,
  PreviewPostData,
  PreviewPostDetail,
  PreviewPostNudge,
  PreviewPostResult,
} from "@/src/domains/content/states/PreviewPost/PreviewPostInitialState";
import PreviewPostInitialState from "@/src/domains/content/states/PreviewPost/PreviewPostInitialState";
import type { IssueNudge, IssueWithReason } from "@/src/domains/content/types/ContentAPITypes";
import type { StanceKey } from "@/src/domains/content/types/Node";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

interface StoreParams {
  data: { nodeId: string; value: PreviewPostDetail };
}
interface UpdateFormConfigParams {
  data: { nodeId: string; formConfig: string };
}
interface RemovePreviewParams {
  data: { nodeId: string };
}

interface StoreResultParams {
  data: {
    nodeId: string;
    suggestedPostType: string;
    postTypeOptions: PostTypeOption[];
    nudges: PreviewPostNudge[];
    issues: IssueNudge[];
    detectedIssues: IssueWithReason[];
    textDiff?: string;
    titleDiff?: string;
    stanceKey?: StanceKey;
  };
}

interface ChangeTypePreview {
  data: { nodeId: string; suggestedPostType: string };
}

interface ChangeStanceKeyPreview {
  data: { nodeId: string; stanceKey?: StanceKey };
}

interface DismissSuggestedIssueTagPreview {
  data: { nodeId: string; flagId: string };
}

interface SetFetchingPreview {
  data: { parentId: string; nodeId?: string; isFetching: boolean; isFetchingError: boolean };
}

export const previewPostSlice = createSlice({
  name: "previewPost",
  initialState: PreviewPostInitialState,
  reducers: {
    store: (state: { previewPost: PreviewPostData }, { payload }: ReducerPayload<StoreParams>) => {
      const { data } = payload;
      if (data) {
        state.previewPost = { ...state.previewPost, [data.nodeId]: { ...data.value } };
      }
    },
    setPreviewButtonState: (
      state: { previewPostConfig: PreviewPostConfig },
      { payload }: ReducerPayload<{ buttonState: PreviewPostButtonState }>,
    ) => {
      const { buttonState } = payload;
      if (buttonState) {
        state.previewPostConfig = { buttonState };
      }
    },
    removePreview: (state: { previewPost: PreviewPostData }, { payload }: ReducerPayload<RemovePreviewParams>) => {
      const { data } = payload;
      if (data?.nodeId) {
        delete state.previewPost[data.nodeId];
      }
    },
    storePreviewResult: (
      state: { previewPostResult: PreviewPostResult },
      { payload }: ReducerPayload<StoreResultParams>,
    ) => {
      const { data } = payload;
      if (data) {
        const { suggestedPostType, postTypeOptions, nudges, textDiff, titleDiff, detectedIssues, stanceKey, issues } =
          data;
        state.previewPostResult = {
          ...state.previewPostResult,
          [data.nodeId]: {
            ...state.previewPostResult?.[data.nodeId],
            suggestedPostType,
            issues,
            detectedIssues,
            postTypeOptions,
            nudges,
            textDiff,
            titleDiff,
            stanceKey,
          },
        };
      }
    },
    removePreviewResult: (
      state: { previewPostResult: PreviewPostResult },
      { payload }: ReducerPayload<RemovePreviewParams>,
    ) => {
      const { data } = payload;
      if (data?.nodeId) {
        delete state.previewPostResult[data.nodeId];
      }
    },
    changeTypePreview: (
      state: { previewPostResult: PreviewPostResult },
      { payload }: ReducerPayload<ChangeTypePreview>,
    ) => {
      const { data } = payload;
      if (data) {
        const { suggestedPostType } = data;
        state.previewPostResult = {
          ...state.previewPostResult,
          [data.nodeId]: { ...state.previewPostResult?.[data.nodeId], suggestedPostType },
        };
      }
    },
    changeStanceKeyPreview: (
      state: { previewPostResult: PreviewPostResult },
      { payload }: ReducerPayload<ChangeStanceKeyPreview>,
    ) => {
      const { data } = payload;
      if (data) {
        const { stanceKey } = data;
        state.previewPostResult = {
          ...state.previewPostResult,
          [data.nodeId]: { ...state.previewPostResult?.[data.nodeId], stanceKey: stanceKey },
        };
      }
    },
    dismissSuggestedIssueTag: (state, { payload }: ReducerPayload<DismissSuggestedIssueTagPreview>) => {
      if (payload.data) {
        const { nodeId, flagId } = payload.data;
        const currentIssues = state.previewPostResult?.[nodeId].detectedIssues || [];
        const updatedIssues = currentIssues.filter(issue => issue.tag.flag_id !== flagId);
        state.previewPostResult = {
          ...state.previewPostResult,
          [nodeId]: { ...state.previewPostResult?.[nodeId], detectedIssues: updatedIssues },
        };
      }
    },
    setLoadingFetch: (
      state: { previewPostResult: PreviewPostResult },
      { payload }: ReducerPayload<SetFetchingPreview>,
    ) => {
      const { data } = payload;
      if (data) {
        const { isFetching, isFetchingError } = data;
        state.previewPostResult = {
          ...state.previewPostResult,
          [data.parentId]: { ...state.previewPostResult?.[data.parentId], isFetching, isFetchingError },
        };
      }
    },
    updateFormConfig: (
      state: { previewPost: PreviewPostData },
      { payload }: ReducerPayload<UpdateFormConfigParams>,
    ) => {
      const { data } = payload;
      if (data) {
        state.previewPost = {
          ...state.previewPost,
          [data.nodeId]: { ...state.previewPost[data.nodeId], formConfig: data.formConfig },
        };
      }
    },
  },
});
