import { createSlice } from "@reduxjs/toolkit";

import type { SpaceActiveDraft } from "@/src/domains/content/types/Node";

interface DraftState {
  activeDrafts: SpaceActiveDraft[];
  isNoMoreData: boolean;
  nextPageToken?: string;
}

type SpaceId = number;
type ReducerState = Record<SpaceId, DraftState>;

export interface SelectorState {
  draftsInSpace: ReducerState;
}

const initialState = {} as ReducerState;

interface StoreDraftsParam extends Omit<DraftState, "isNoMoreData"> {
  spaceId: number;
  appendPagination?: boolean;
}

interface InsertDraftParams {
  spaceId: number;
  data: SpaceActiveDraft;
}

interface DeleteDraftParams {
  spaceId: number;
  draftId: number;
}

const initialSpaceDraftData: DraftState = {
  activeDrafts: [],
  isNoMoreData: false,
};

export const draftsInSpace = createSlice({
  name: "draftsInSpace",
  initialState,
  reducers: {
    storeDraft: (state, { payload }: { payload: StoreDraftsParam }) => {
      const { spaceId, appendPagination, nextPageToken, activeDrafts } = payload;

      if (!state[spaceId]) {
        state[spaceId] = initialSpaceDraftData;
      }

      let storedActiveDrafts;

      if (appendPagination) {
        // Ensure it to always be in a correct order and no duplicate items
        storedActiveDrafts = state[spaceId].activeDrafts
          .filter(activeDraft => !activeDrafts.find(p => p.draftId === activeDraft.draftId))
          .concat(activeDrafts)
          .sort((a, b) => b.draftId - a.draftId);
      } else {
        storedActiveDrafts = activeDrafts;
      }

      state[spaceId] = {
        ...state[spaceId],
        activeDrafts: storedActiveDrafts,
        nextPageToken: nextPageToken,
        isNoMoreData: !nextPageToken || activeDrafts.length === 0,
      };
    },
    optimisticInsertDraft: (state, { payload }: { payload: InsertDraftParams }) => {
      const { spaceId, data } = payload;
      const draftInSpace = state[spaceId];

      if (draftInSpace) {
        const updatedActiveDrafts = draftInSpace.activeDrafts.filter(p => p.draftId !== data.draftId);
        updatedActiveDrafts.unshift(data);
        updatedActiveDrafts.sort((a, b) => b.draftId - a.draftId);

        state[spaceId] = {
          ...state[spaceId],
          activeDrafts: updatedActiveDrafts,
        };
      }
    },
    optimisticDeleteDraft: (state, { payload }: { payload: DeleteDraftParams }) => {
      const { spaceId, draftId } = payload;
      const draftInSpace = state[spaceId];

      if (draftInSpace) {
        const updatedActiveDrafts = draftInSpace.activeDrafts.filter(draft => {
          return draftId !== draft.draftId;
        });

        state[spaceId] = {
          ...state[spaceId],
          activeDrafts: updatedActiveDrafts,
        };
      }
    },
  },
});
