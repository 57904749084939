import { createSlice } from "@reduxjs/toolkit";

import { USER_PRESENCE_STATUS } from "@/src/constants/AppConstant";
import type { UserPresenceData } from "@/src/hooks/pubsub/topicHandlers/useUserPresenceTopicHandler";

interface PresenceState {
  users: UserPresenceData[];
}

const initialState: PresenceState = {
  users: [],
};

const presenceSlice = createSlice({
  name: "presence",
  initialState,
  reducers: {
    setUsersPresence: (state, { payload }: { payload: UserPresenceData[] }) => {
      state.users = payload;
    },
  },
});

export const { setUsersPresence } = presenceSlice.actions;

export const isOnlineByUserIdSelector = (userId: number) => (state: { presence: PresenceState }) => {
  return state.presence.users.find(user => user.userId === userId)?.status === USER_PRESENCE_STATUS.AVAILABLE;
};

export default presenceSlice;
