import { createSlice } from "@reduxjs/toolkit";

import type { SpaceActiveDraft } from "@/src/domains/content/types/Node";

interface DraftState {
  activeDrafts: SpaceActiveDraft[];
  isNoMoreData: boolean;
  nextPageToken?: string;
}

type ReducerState = DraftState;

export interface SelectorState {
  drafts: ReducerState;
}

const initialState = {
  activeDrafts: [],
  isNoMoreData: false,
} as ReducerState;

interface StoreDraftAndMyPostsParam extends Omit<DraftState, "isNoMoreData"> {
  isFirstPage: boolean;
}

interface ToggleNoMoreDataParams {
  isNoMoreData?: boolean;
}
interface InsertDraftParams {
  data: SpaceActiveDraft;
}
interface DeleteDraftParams {
  draftId: number;
}

export const drafts = createSlice({
  name: "drafts",
  initialState,
  reducers: {
    storeDraft: (state, { payload }: { payload: StoreDraftAndMyPostsParam }) => {
      const { isFirstPage, nextPageToken, activeDrafts } = payload;

      const updatedDraftState = { ...(state ?? initialState) };

      if (isFirstPage) {
        updatedDraftState.activeDrafts = activeDrafts;
      } else {
        // Ensure it to always be in a correct order and no duplicate items
        updatedDraftState.activeDrafts = state?.activeDrafts
          ?.filter(activeDraft => !activeDrafts.find(p => p.draftId === activeDraft.draftId))
          ?.concat(activeDrafts)
          ?.sort((a, b) => b.draftId - a.draftId);
      }

      updatedDraftState.nextPageToken = nextPageToken;
      return updatedDraftState;
    },
    toggleNoMoreData: (state, { payload }: { payload: ToggleNoMoreDataParams }) => {
      const { isNoMoreData } = payload;

      const updatedDraftState = { ...(state ?? initialState) };

      if (isNoMoreData) {
        updatedDraftState.isNoMoreData = isNoMoreData;
      }

      return updatedDraftState;
    },
    resetDraft: state => {
      state = initialState;
      return state;
    },
    optimisticInsertDraft: (state, { payload }: { payload: InsertDraftParams }) => {
      const { data } = payload;

      const updatedDraftState = state.activeDrafts.filter(p => p.draftId !== data.draftId);
      updatedDraftState.unshift(data);
      updatedDraftState.sort((a, b) => b.draftId - a.draftId);

      state.activeDrafts = updatedDraftState;
    },
    optimisticDeleteDraft: (state, { payload }: { payload: DeleteDraftParams }) => {
      const { draftId } = payload;
      const draftState = { ...(state ?? initialState) };
      const updatedDraftState = draftState?.activeDrafts?.filter(draft => draft.draftId !== draftId);
      state.activeDrafts = updatedDraftState;
      return state;
    },
  },
});
