import type { BadgeIconType } from "@/src/components/sembly-ui/components/Badge/constants";
import type { EditUserContextDisplaySpecKeyType } from "@/src/domains/liquidity/components/ContextDisplay/components/EditUserContextDisplaySpec/EditUserContextDisplaySpec";
import type { RichIcon } from "@/src/domains/liquidity/components/RichAvatar/RichIcon";
import type { UserNotificationIconType } from "@/src/domains/liquidity/components/UserNotification/types/UserNotificationApiTypes";
import type { ActionDisplay } from "@/src/domains/liquidity/types/Activity";
import type { ActionType } from "@/src/domains/types/NodeActionType";
import type { TaskDisabledReasonType } from "@/src/pages/TasksPage/SpaceGuestReviewTask/SpaceGuestReviewTaskOverview";
import type { TimestampInNano } from "@/src/utils/general/TimeUtil";
import type { ValueOf } from "@/src/utils/helpers/TypeUtil";

export const TASK_TYPE = {
  NORMAL: "NORMAL",
  ADMINISTRATIVE: "ADMINISTRATIVE",
} as const;
export type TaskType = ValueOf<typeof TASK_TYPE>;

export const TASK_STATUS = {
  NEW: "NEW",
  STARTED: "STARTED",
  CLOSING: "CLOSING",
  FINISHED: "FINISHED",
  FAILED: "FAILED",
} as const;

export type TaskStatus = ValueOf<typeof TASK_STATUS>;

export const TASK_VOTE_STATUS = {
  NEW: "NEW",
  SUBMITTED: "SUBMITTED",
  PROCESSED: "PROCESSED", // Currently BE is not using it
  EXPIRED: "EXPIRED",
  REJECTED: "REJECTED", // User skip the vote
} as const;

export type TaskVoteStatus = ValueOf<typeof TASK_VOTE_STATUS>;

export const QUESTION_TYPE = {
  SCALAR: "SCALAR",
  MULTI_CHECKBOXES: "MULTI_CHECKBOXES",
} as const;

export type QuestionType = ValueOf<typeof QUESTION_TYPE>;

export interface ConsensusTask {
  timestamp: number;
  actionType: ActionType;
  id: number;
  entityId: number;
  entityType: string;
  consensusParam: ConsensusParam;
  expirationSpec: ExpirationSpec;
  /**
   * @deprecated contextDisplaySpec is deprecated from BE as it's hard to maintain. Use the display instead whenever possible.
   */
  contextDisplaySpec: ContextDisplaySpec[];
  display: ActionDisplay;
  state: TaskStatus;
  debugMetadata?: DebugMetadata;
  isDebugMode?: boolean;
  reason?: string;
  reasonLabel?: string;
  title: string;
  infoText?: string;
  space: SpaceDisplaySpec;
  submittedAt: TimestampInNano;
  requesterVoteStatus: TaskVoteStatus;
  isInitiatedByRequester?: boolean;
  isHighPriority?: boolean;
  summaryDisplay?: SummaryDisplay;
  isVotingDisabled?: boolean;
  isVotingDisabledReasonType?: TaskDisabledReasonType;
}

export interface SpaceDisplaySpec {
  spaceId: number;
  spaceCanonicalId: string;
  spaceIcon: string;
  spaceName: string;
}

export interface SummaryDisplay {
  text: string;
  icon: {
    type: UserNotificationIconType;
    url: string;
    name: string;
  };
  richIcon: RichIcon;
}

export interface ConsensusParam {
  questions: Question[];
  mainTitle?: string;
}

export interface Question {
  id: number;
  questionType: QuestionType;
  displaySpec: DisplaySpec;
}

export interface DisplaySpec {
  text: string;
  binaryOptions: BinaryOption[];
  multiCheckboxesOptions: MultiCheckboxesOption[];
  groups: Record<string, string>;
}

export interface BinaryOption {
  id: string;
  value: string;
}

export interface MultiCheckboxesOption {
  id: string;
  value: string;
  groupId: string;
  isSelectedByDefault: boolean;
  nodeSpec?: {
    nodeComponentId: string;
    issueTagTitle?: string;
    issueTagDescription: string;
  };
}

export interface ComparisonContext {
  isUpdated: boolean;
  originalValue: unknown;
  proposedValue: unknown;
}

// TODO(lungsin): define Display struct from BE.

/**
 * @deprecated ContextDisplaySpec is deprecated from BE as it's hard to maintain. Use the Display instead whenever possible.
 */
export interface ContextDisplaySpec {
  title: string;
  entityProfileContextDisplaySpec: EntityProfileContextDisplaySpec;
  entityContextDisplaySpec: EntityContextDisplaySpec;
  contentContextDisplaySpec: { [key: string]: unknown };
  editSpaceContextDisplaySpec: { [key: string]: unknown };
  editBadgeContextDisplaySpec: { [key: string]: ComparisonContext };
  editUserContextDisplaySpec: { [key in EditUserContextDisplaySpecKeyType]: ComparisonContext };
  footer?: Footer;
}

export interface Footer {
  link: Link;
  breadcrumb: Breadcrumb[];
}
export interface Link {
  type: string;
  space: Space;
  // This field is commented since Node is not compatible with the Immer library used by redux toolkit.
  // Currently, this field is only used by FooterLink, and FooterLink doesn't seem to be used anywhere in the codebase.
  // node: Node;
}
export interface Breadcrumb {
  text: string;
  id: string;
  breadcrumbType: string;
}

export interface Space {
  canonicalId: string;
}

export interface EntityContextDisplaySpec {
  title: string;
  text: string;
  detailContext: DetailContext;
}

export interface DetailContext {
  title: string;
  contexts: Context[];
}

export interface Context {
  head: string;
  description: string;
}

export interface EntityProfileContextDisplaySpec {
  title: string;
  entityText: string;
  entityType: string;
  entityIcon: BadgeIconType;
  entityId: number;
  entityDescription: string;
}

export type DebugMetadata = { [key: string]: unknown };

export interface ExpirationSpec {
  remainingTimeSec: number;
  expirationTimeInSec: number;
}
