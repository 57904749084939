import { createApi } from "@reduxjs/toolkit/query/react";

import type { AIAssistantJobResult, BaseJob } from "@/src/components/AIAssistant/types";
import {
  API_ENDPOINT_AI_ASSISTANT_GENERATE_POSTS,
  API_ENDPOINT_AI_ASSISTANT_GENERATE_REVISION,
  API_ENDPOINT_AI_ASSISTANT_GENERATE_SECTIONS,
  API_ENDPOINT_AI_ASSISTANT_GET_JOB,
  API_ENDPOINT_AI_ASSISTANT_GET_JOBS,
  API_ENDPOINT_AI_ASSISTANT_SUBMIT_GENERATED_RESULT,
  API_ENDPOINT_GET_RESOLVABLE_OPTIONS,
} from "@/src/constants/AppConstant";
import type { FetchArgs } from "@/src/core/AxiosRTKQuery";
import axiosBaseQuery from "@/src/core/AxiosRTKQuery";
import type { ResolvableOptionsForRevisionResponse } from "@/src/domains/dynamic-form/components/DynamicForm/hooks/useDataEndpoint";

type GetJobsResponse = {
  jobs: BaseJob[];
  status: number;
};

type GetJobsRequest = {
  spaceId: number;
  pageToken?: string;
};

type GetJobResponse = {
  job: AIAssistantJobResult;
  status: number;
};

type GetJobRequest = {
  jobId: string;
};

export interface SubmitGeneratedRequest {
  jobId: string;
  resultId: string;
  valueIds: string[];
}

interface GetResolvableOptionsForRevisionRequest {
  nodeId: string;
}

interface GenerateRevisionRequest {
  actionNodeId: string;
  param: {
    note?: string;
    targetResolvableOptionIds?: string[];
  };
}

interface GenerateSectionsRequest {
  actionNodeId: string;
  param?: {
    note?: string;
    autocompleteSectionTree?: boolean;
    // Valid range: [1, 10]
    minDirectSections?: number;
    // Valid range: [1, 5]
    minHeight?: number;
  };
}

interface GeneratePostRequest {
  actionNodeId: string;
  param?: {
    note?: string;
    considerExistingPosts?: boolean;
    maxNumPostsWithResponse?: number;
  };
}

export const aiAssistantJobsAPI = createApi({
  reducerPath: "api-ai-assistant",
  baseQuery: axiosBaseQuery,
  endpoints: builder => ({
    getAIAssistantJobs: builder.query<GetJobsResponse, GetJobsRequest>({
      query: (args): FetchArgs => ({
        url: API_ENDPOINT_AI_ASSISTANT_GET_JOBS,
        method: "POST",
        data: args,
      }),
    }),
    getAIAssistantJob: builder.query<GetJobResponse, GetJobRequest>({
      query: (args): FetchArgs => ({
        url: API_ENDPOINT_AI_ASSISTANT_GET_JOB,
        method: "POST",
        data: args,
      }),
    }),
    getResolvableOptions: builder.query<ResolvableOptionsForRevisionResponse, GetResolvableOptionsForRevisionRequest>({
      query: (args): FetchArgs => ({
        url: API_ENDPOINT_GET_RESOLVABLE_OPTIONS,
        method: "POST",
        data: {
          ...args,
        },
      }),
    }),
    submitGeneratedResults: builder.mutation<{}, SubmitGeneratedRequest>({
      query: (args): FetchArgs => ({
        url: API_ENDPOINT_AI_ASSISTANT_SUBMIT_GENERATED_RESULT,
        method: "POST",
        data: {
          ...args,
        },
      }),
    }),
    generateRevision: builder.mutation<{}, GenerateRevisionRequest>({
      query: (args): FetchArgs => ({
        url: API_ENDPOINT_AI_ASSISTANT_GENERATE_REVISION,
        method: "POST",
        data: {
          ...args,
        },
      }),
    }),
    generatePosts: builder.mutation<{}, GeneratePostRequest>({
      query: (args): FetchArgs => ({
        url: API_ENDPOINT_AI_ASSISTANT_GENERATE_POSTS,
        method: "POST",
        data: {
          ...args,
        },
      }),
    }),
    generateSections: builder.mutation<{}, GenerateSectionsRequest>({
      query: (args): FetchArgs => ({
        url: API_ENDPOINT_AI_ASSISTANT_GENERATE_SECTIONS,
        method: "POST",
        data: {
          ...args,
        },
      }),
    }),
  }),
});

export const {
  useGetAIAssistantJobsQuery,
  useLazyGetAIAssistantJobsQuery,
  useGetAIAssistantJobQuery,
  useGetResolvableOptionsQuery,
  useSubmitGeneratedResultsMutation,
  useGenerateRevisionMutation,
  useGeneratePostsMutation,
  useGenerateSectionsMutation,
} = aiAssistantJobsAPI;
