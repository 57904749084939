import type { ValueOf } from "@/src/utils/helpers/TypeUtil";

export const SHARED_ACTION_BETWEEN_MORE_AND_ACTION_TYPE = {
  MOVE_PARENT: "MOVE_PARENT",
  MOVE_REPLY: "MOVE_REPLY",
  DELETE: "DELETE",
  DELETE_ALL_SAMPLE_POSTS: "DELETE_ALL_SAMPLE_POSTS",
  DELETE_SECTION_GROUP: "DELETE_SECTION_GROUP",
  DELETE_SECTION: "DELETE_SECTION",
  MARK_OBSOLETE: "MARK_OBSOLETE",
  MARK_NOT_OBSOLETE: "MARK_NOT_OBSOLETE",
  MARK_DUPLICATE: "MARK_DUPLICATE",
  MARK_NOT_DUPLICATE: "MARK_NOT_DUPLICATE",
  PIN_NODE: "PIN_NODE",
  EDIT_POST_TYPE: "EDIT_POST_TYPE",
  TAG_ISSUE: "TAG_ISSUE",
  UPDATE_ISSUE: "UPDATE_ISSUE",
  ADD_SECTION_GROUP: "ADD_PARTITION",
  ADD_SECTION_GROUP_ABOVE: "ADD_PARTITION_ABOVE",
  ADD_SECTION_GROUP_BELOW: "ADD_PARTITION_BELOW",
  ADD_SECTION: "ADD_SECTION",
  ADD_SECTION_ABOVE: "ADD_SECTION_ABOVE",
  ADD_SECTION_BELOW: "ADD_SECTION_BELOW",
  UPDATE_SECTION: "UPDATE_SECTION",
  UPDATE_SECTION_TYPE: "UPDATE_SECTION_TYPE",
  RENAME_SECTION_GROUP: "RENAME_SECTION_GROUP",
  RENAME_SECTION: "RENAME_SECTION",
  ADD_SECTION_DESCRIPTION: "ADD_SECTION_DESCRIPTION",
  EDIT_SECTION_DESCRIPTION: "EDIT_SECTION_DESCRIPTION",
  REORDER_SECTION: "REORDER_SECTION",
  ARCHIVE_SECTION_GROUP: "ARCHIVE_SECTION_GROUP",
  UNARCHIVE_SECTION_GROUP: "UNARCHIVE_SECTION_GROUP",
  ARCHIVE_SECTION: "ARCHIVE_SECTION",
  UNARCHIVE_SECTION: "UNARCHIVE_SECTION",
  ADD_SECTION_TAG: "ADD_SECTION_TAG",
  CLONE_SECTION: "CLONE_SECTION",
  CLONE_ROOT_POST: "CLONE_ROOT_POST",
  ACCEPT: "ACCEPT",
  REJECT: "REJECT",
  RETRACT: "RETRACT",
  REJECT_FEEDBACK: "REJECT_FEEDBACK",
  RETRACT_FEEDBACK: "RETRACT_FEEDBACK",
  SET_USER_ANONYMOUS: "SET_USER_ANONYMOUS",
  SET_USER_PUBLIC: "SET_USER_PUBLIC",
  EDIT_OBSOLETE_LINK: "EDIT_OBSOLETE_LINK",
  EDIT_DUPLICATE_LINK: "EDIT_DUPLICATE_LINK",
  RESOLVE_FEEDBACK: "RESOLVE_FEEDBACK",
  MENTION_USER: "MENTION_USER",
  CLOSE: "CLOSE",
  VIEW_SECTION_HISTORY: "VIEW_SECTION_HISTORY",
} as const;

export const MORE_ACTIONS = {
  ...SHARED_ACTION_BETWEEN_MORE_AND_ACTION_TYPE,
  FLAG_SOURCE: "MARK_AS",
  FLAG_NODE: "ADD_ISSUE_FLAG",
  UNFLAG_NODE: "REMOVE_ISSUE_FLAG",
  EDIT: "EDIT_TRAY",
  FOLLOW_CONTENT: "FOLLOW_CONTENT",
  UNFOLLOW_CONTENT: "UNFOLLOW_CONTENT",
  COPY_TO_SPACE: "COPY_TO_SPACE",
  MARK_READ: "MARK_READ",
  REFINE: "REFINE",
  ADD_RESPONSE: "ADD_RESPONSE",
  EDIT_POST: "EDIT_POST",
  ADD_SUBSECTION: "ADD_SUBSECTION",
  UPDATE_SECTION_GROUP: "UPDATE_PARTITION",
  VIEW_HISTORY: "VIEW_HISTORY",
  VIEW_EDIT_HISTORY: "VIEW_EDIT_HISTORY",
  HIDE_FEATURED_POST: "HIDE_FEATURED_POST",
  SHOW_FEATURED_POST: "SHOW_FEATURED_POST",
  REPORT: "REPORT",
  REPLY_TO_POST: "REPLY_TO_POST",
  ENABLE_EDIT_TOC: "ENABLE_EDIT_TOC",
  MARK_UNREAD: "MARK_UNREAD",
  COPY_LINK: "COPY_LINK",
  SHARE_NODE: "SHARE_NODE",
  CLOSURE_GROUP: "CLOSURE_GROUP",
} as const;

export const NODE_ACTION_TYPE = {
  ...SHARED_ACTION_BETWEEN_MORE_AND_ACTION_TYPE,
  NOOP: "NOOP",
  UPDATE_TOC: "UPDATE_TOC",
  ADD_NEW_POST_FORUM: "ADD_NEW_POST_FORUM",
  ADD_NEW_POST_SPACE_TALK: "ADD_NEW_POST_SPACE_TALK",
  ADD_NEW: "ADD_NEW",
  ADD_NEW_QUESTION: "ADD_NEW_QUESTION",
  ADD_NEW_CLAIM: "ADD_NEW_CLAIM",
  ADD_NEW_PART_OF_DOCUMENT: "ADD_NEW_PART_OF_DOCUMENT",
  ADD_NEW_ABOVE_PART_OF_DOCUMENT: "ADD_NEW_ABOVE_PART_OF_DOCUMENT",
  ADD_NEW_BELOW_PART_OF_DOCUMENT: "ADD_NEW_BELOW_PART_OF_DOCUMENT",
  ADD_NEW_INFORMATIONAL: "ADD_NEW_INFORMATIONAL",
  ADD_NEW_SOCIAL: "ADD_NEW_SOCIAL",
  ADD_NEW_POLL: "ADD_NEW_POLL",
  REPLY_TO_RESOURCE: "REPLY_TO_RESOURCE",
  REPLY_TO_QUESTION: "REPLY_TO_QUESTION",
  REPLY_TO_POST: "REPLY_TO_POST",
  REPLY_TO_CLAIM: "REPLY_TO_CLAIM",
  REPLY_TO_SUPPORT: "REPLY_TO_SUPPORT",
  REPLY_TO_FEEDBACK: "REPLY_TO_FEEDBACK",
  REPLY_TO_REPLY: "REPLY_TO_REPLY",
  REPLY_TO_INFORMAL: "REPLY_TO_INFORMAL",
  REPLY_TO_INFORMATIONAL: "REPLY_TO_INFORMATIONAL",
  REPLY_TO_PART_OF_DOCUMENT: "REPLY_TO_PART_OF_DOCUMENT",
  REPLY_TO_COMMENT: "REPLY_TO_COMMENT",
  REPLY_TO_SOCIAL_POST: "REPLY_TO_SOCIAL_POST",
  REVISE: "REVISE",
  ADDRESS_FEEDBACK: "ADDRESS_FEEDBACK",
  EDIT_TEXT: "EDIT_TEXT",
  EDIT_COMMENT: "EDIT_COMMENT",
  EDIT_POST_TYPE_CLAIM: "EDIT_POST_TYPE_CLAIM",
  EDIT_POST_TYPE_QUESTION: "EDIT_POST_TYPE_QUESTION",
  REOPEN: "REOPEN",
  REINSTATE: "REINSTATE",
  REOPEN_FEEDBACK: "REOPEN_FEEDBACK",
  REINSTATE_FEEDBACK: "REINSTATE_FEEDBACK",
  REMOVE_SECTION_TAG: "REMOVE_SECTION_TAG",
  MARK_SPAM: "MARK_SPAM",
  MARK_BIASED: "MARK_BIASED",
  MARK_NEED_EDITING: "MARK_NEED_EDITING",
  MARK_INVALID_SOURCE: "MARK_INVALID_SOURCE",
  MARK_WRONG_CATEGORIZATION: "MARK_WRONG_CATEGORIZATION",
  MARK_IRRELEVANT: "MARK_IRRELEVANT",
  MARK_NOT_CREDIBLE: "MARK_NOT_CREDIBLE",
  MARK_REQUIRE_3rd_PARTY_LOGIN: "MARK_REQUIRE_3rd_PARTY_LOGIN",
  MARK_OFFENSIVE: "MARK_OFFENSIVE",
  MARK_SENSITIVE: "MARK_SENSITIVE",
  MARK_DUMMY: "MARK_DUMMY",
  ADD_ISSUE_FLAG: "ADD_ISSUE_FLAG",
  REMOVE_ISSUE_FLAG: "REMOVE_ISSUE_FLAG",
  MOVE_FIXED_ORDER_NODE: "MOVE_FIXED_ORDER_NODE",
  UNPIN_NODE: "UNPIN_NODE",
  MOVE_CHILD_NODE: "MOVE_CHILD_NODE",
  UPVOTE: "UPVOTE",
  UNVOTE: "UNVOTE",
  INSERT_POLL_VOTE: "INSERT_POLL_VOTE",
  ADD_REACTION: "ADD_REACTION",
  REMOVE_REACTION: "REMOVE_REACTION",
  ENDORSE_NODE: "ENDORSE_NODE",
  UNENDORSE_NODE: "UNENDORSE_NODE",
  REFRESH: "REFRESH",
  REVIEW_PENDING_ACTION: "REVIEW_PENDING_ACTION",
  FOLLOW_USER: "FOLLOW_USER",
  UNFOLLOW_USER: "UNFOLLOW_USER",
  CALIBRATE_USER_REPUTATION: "CALIBRATE_USER_REPUTATION",
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  INSERT_SPACE: "INSERT_SPACE",
  JOIN_SPACE: "JOIN_SPACE",
  EDIT_SPACE: "EDIT_SPACE",
  SUBMIT_PERMANENT_SPACE_CONVERSION: "SUBMIT_PERMANENT_SPACE_CONVERSION",
  CREATE_SPACE: "CREATE_SPACE",
  CREATE_SPACE_PROPOSAL: "CREATE_SPACE_PROPOSAL",
  PROCESS_SPACE_PROPOSAL: "PROCESS_SPACE_PROPOSAL",
  CONVERT_REC_TO_PILOT_SPACE: "CONVERT_REC_TO_PILOT_SPACE",
  PROCESS_SPACE_ACT_REQ: "PROCESS_SPACE_ACT_REQ",
  PROCESS_PERM_SPACE_ACT_PRPSL: "PROCESS_PERM_SPACE_ACT_PRPSL",
  PROCESS_LIM_SPACE_ACT_PRPSL: "PROCESS_LIM_SPACE_ACT_PRPSL",
  PROCESS_CHG_SPACE_BRD_ADM: "PROCESS_CHG_SPACE_BRD_ADM",
  PROCESS_CHG_SPACE_PUBLIC: "PROCESS_CHG_SPACE_PUBLIC",
  PROCESS_PUBLISH_OPEN: "PROCESS_PUBLISH_OPEN",
  SUBMIT_PERM_SPACE_ACT_PRPSL: "SUBMIT_PERM_SPACE_ACT_PRPSL",
  SUBMIT_LIM_SPACE_ACT_PRPSL: "SUBMIT_LIM_SPACE_ACT_PRPSL",
  SUBMIT_CHG_SPACE_TYPE_PRPSL: "SUBMIT_CHG_SPACE_TYPE_PRPSL",
  SUBMIT_CHG_SPACE_BRD_ADM: "SUBMIT_CHG_SPACE_BRD_ADM",
  SUBMIT_CHG_SPACE_PUBLIC: "SUBMIT_CHG_SPACE_PUBLIC",
  SUBMIT_PUBLISH_OPEN: "SUBMIT_PUBLISH_OPEN",
  PUBLISH_SPACE_TEMPLATE: "PUBLISH_SPACE_TEMPLATE",
  UNPUBLISH_SPACE_TEMPLATE: "UNPUBLISH_SPACE_TEMPLATE",
  LEAVE_SPACE: "LEAVE_SPACE",
  KICK_USER_FROM_SPACE: "KICK_USER_FROM_SPACE",
  UPDATE_SPACE_USER_PRIVILEGE: "UPDATE_SPACE_USER_PRIVILEGE",
  UPDATE_SPACE_PROGRESS: "UPDATE_SPACE_PROGRESS",
  CREATE_BADGE: "CREATE_BADGE",
  DELETE_BADGE: "DELETE_BADGE",
  EDIT_BADGE: "EDIT_BADGE",
  ASSIGN_BADGE: "ASSIGN_BADGE",
  REVOKE_BADGE: "REVOKE_BADGE",
  UPDATE_BADGE_VISIBILITY: "UPDATE_BADGE_VISIBILITY",
  PUBLISH_BRAND_ADM_SPACE: "PUBLISH_BRAND_ADM_SPACE",
  UNPUBLISH_BRAND_ADM_SPACE: "UNPUBLISH_BRAND_ADM_SPACE",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  REQUEST_CHANGE_EMAIL: "REQUEST_CHANGE_EMAIL",
  DEACTIVATE_ACCOUNT: "DEACTIVATE_ACCOUNT",
  UPDATE_SETTING_GENERAL: "UPDATE_SETTING_GENERAL",
  DISMISS: "DISMISS",
  REMOVE_LABEL: "REMOVE_LABEL",
  MARK_ADDRESSED: "MARK_ADDRESSED",
  MARK_UNADDRESSED: "MARK_UNADDRESSED",
  CONCLUDE_BY_VOTING: "CONCLUDE_BY_VOTING",
} as const;

export type ShareActionBetweenMoreAndActionType = ValueOf<typeof SHARED_ACTION_BETWEEN_MORE_AND_ACTION_TYPE>;
export type ActionType = (typeof NODE_ACTION_TYPE)[keyof typeof NODE_ACTION_TYPE];
export type MoreActionType = (typeof MORE_ACTIONS)[keyof typeof MORE_ACTIONS];

export const ACTION_GROUP_ID = {
  REPLY_TRAY: "REPLY_TRAY",
  REVISE_TRAY: "REVISE_TRAY",
  RESOLVE_FEEDBACK_TRAY: "RESOLVE_FEEDBACK_TRAY",
  REOPEN_TRAY: "REOPEN_TRAY",
  REOPEN_FEEDBACK_TRAY: "REOPEN_FEEDBACK_TRAY",
  RETRACT_TRAY: "RETRACT_TRAY",
  RETRACT_FEEDBACK_TRAY: "RETRACT_FEEDBACK_TRAY",
  REINSTATE_TRAY: "REINSTATE_TRAY",
  REMOVE_ISSUE_FLAG_TRAY: "REMOVE_ISSUE_FLAG_TRAY",
  REINSTATE_FEEDBACK_TRAY: "REINSTATE_FEEDBACK_TRAY",
  FOCUS_TRAY: "FOCUS_TRAY",
  EDIT_TRAY: "EDIT_TRAY",
  MORE_TRAY: "MORE_TRAY",
  ANSWER_TRAY: "ANSWER_TRAY",
  DISCUSS_TRAY: "DISCUSS_TRAY",
  REACT_TRAY: "REACT_TRAY",
  UPVOTE_TRAY: "UPVOTE_TRAY",
  REORDER_TRAY: "REORDER_TRAY",
  POLL_VOTE_TRAY: "POLL_VOTE_TRAY",
  REACTION_TRAY: "REACTION_TRAY",
  ENDORSE_TRAY: "ENDORSE_TRAY",
  ADD_CONTRIBUTION_TRAY: "ADD_CONTRIBUTION_TRAY",
  ADD_NEW_POST: "ADD_NEW_POST",
  MARK_AS: "MARK_AS",
  CLOSURE_TRAY: "CLOSURE_TRAY",
} as const;
export type ActionGroupId = (typeof ACTION_GROUP_ID)[keyof typeof ACTION_GROUP_ID];
